import React from 'react'
import { Route } from 'react-router-dom'
import { connect } from 'react-redux'
import { useInView } from 'react-intersection-observer'
import styled from 'styled-components'
import LayoutContainer from 'components/Common/LayoutContainer'
import BodyText from 'components/Luxkit/Typography/BodyText'
import Image from 'components/Common/Image'
import { mediaQueryUp } from 'components/utils/breakpoint'
import { rem } from 'polished'
import config from 'constants/config'
import LineRssIcon from 'components/Luxkit/Icons/line/LineRssIcon'
import PinterestIcon from 'components/Luxkit/Icons/custom/PinterestIcon'
import LineInstagramIcon from 'components/Luxkit/Icons/line/LineInstagramIcon'
import LineFacebookIcon from 'components/Luxkit/Icons/line/LineFacebookIcon'
import DestinationsCarousels from 'components/Common/DestinationsCarousel/DestinationCarousels'
import ClickableLink from 'components/Common/Clickable/ClickableLink'
import TextLink from 'components/Luxkit/TextLink'
import TopDestinationList from 'components/Common/TopDestinationsList/TopDestinationList'
import useWhiteLabelAppConfig from 'hooks/useWhiteLabelAppConfig'
import Group from 'components/utils/Group'
import IconButton from 'components/Luxkit/Button/IconButton'
import SvgIcon from 'components/Luxkit/SvgIcon'

const Container = styled.div`
  padding: ${rem(16)} 0;
  background-color: ${props => props.theme.palette.neutral.default.eight};

  @media print {
    a[href]:after {
      content: "";
    }
  }
`

const AppLogo = styled(Image)`
  max-width: 100%;
`

const AppLogoLink = styled(ClickableLink)`
  width: ${rem(128)};
`

const TopSectionWrapper = styled.div`
  padding: ${rem(32)} 0;
  background-color: ${props => props.theme.palette.neutral.default.eight};
  border-top: 1px solid ${props => props.theme.palette.neutral.default.five};
  text-align: center;

  ${mediaQueryUp.tablet} {
    text-align: left;
  }
`

interface SocialLink {
  href: string
  Icon: typeof SvgIcon
}

function getSocialLinks(): Array<[key: string, link: SocialLink]> {
  const links = new Map<string, SocialLink>()
  if (config.footer.links?.facebook) links.set('facebook', { href: config.footer.links.facebook, Icon: LineFacebookIcon })
  if (config.footer.links?.instagram) links.set('instagram', { href: config.footer.links.instagram, Icon: LineInstagramIcon })
  // Is Pinterest misspelled intentionally?!
  if (config.footer.links?.pintrest) links.set('pinterest', { href: config.footer.links.pintrest, Icon: PinterestIcon })
  if (config.footer.links?.blog) links.set('blog', { href: config.footer.links.blog, Icon: LineRssIcon })
  return Array.from(links.entries())
}

const socialLinks = getSocialLinks()

function TopDestinationListWithProps() {
  return <TopDestinationList destinationNavItems={['Cities', 'Popular', 'Countries']} />
}

function CompactFooter() {
  const [footerRef, footerInView] = useInView({
    triggerOnce: true, // Load it once and keep it loaded
    rootMargin: '500px', // Trigger 500px before the component is reached
  })

  const whiteLabelAppConfig = useWhiteLabelAppConfig()

  return <Container ref={footerRef}>
    {footerInView && <TopSectionWrapper>
      <Route exact path="/:regionCode" component={DestinationsCarousels} />
      <Route exact path="/:regionCode" component={TopDestinationList} />
      <Route exact path="/:regionCode/(hotels|experiences|tours|last-minute|flights)" render={TopDestinationListWithProps} />
    </TopSectionWrapper>}

    <LayoutContainer size="lg">
      <Group direction="vertical" gap={32}>
        {!config.MOBILE_APP_CUSTOMISATIONS && <Group
          direction="vertical"
          desktopDirection="horizontal"
          horizontalAlign="center"
          desktopHorizontalAlign="space-between"
          verticalAlign="center"
          gap={32}
        >
          <Group direction="horizontal" gap={16}>
            <TextLink href="/au" size="large">Home</TextLink>
            {config.header.faq && <TextLink href={config.header.faq.href} size="large">FAQs</TextLink>}
          </Group>
          <Group direction="vertical" tabletDirection="horizontal" horizontalAlign="center" gap={24} wrap="wrap">
            {!!socialLinks.length && <Group direction="vertical" horizontalAlign="center" gap={8}>
              <BodyText variant="large" align="center" format="uppercase" weight="bold">Follow Us</BodyText>
              <Group direction="horizontal" horizontalAlign="center" gap={8}>
                {socialLinks.map(([key, socialLink]) => <IconButton
                  key={key}
                  href={socialLink.href}
                  target="_blank"
                  rel="noopener noreferrer"
                  kind="primary"
                  shape="circle"
                >
                  <socialLink.Icon />
                </IconButton>)}
              </Group>
            </Group>}
            {!config.HIDE_MOBILE_APPS && !whiteLabelAppConfig.isApp && <Group direction="vertical" horizontalAlign="center" gap={8}>
              <BodyText variant="large" align="center" format="uppercase" weight="bold">Download our free app</BodyText>
              <Group direction="horizontal" horizontalAlign="center" gap={8}>
                {!!config.footer.links?.ios && <AppLogoLink href={config.footer.links.ios} target="_blank" rel="noopener noreferrer"><AppLogo id="apple_jch2pt" alt="play-store-logo"/></AppLogoLink>}
                {!!config.footer.links?.android && <AppLogoLink href={config.footer.links.android} target="_blank" rel="noopener noreferrer"><AppLogo id="google_play_ytxm3z" alt="play-store-logo"/></AppLogoLink>}
              </Group>
            </Group>}
          </Group>
        </Group>}

        <Group direction="horizontal" horizontalAlign="center" desktopHorizontalAlign="start" gap={8} wrap="wrap">
          <BodyText variant="large">&copy; 2023 {config.title} - All Rights Reserved.</BodyText>
          <BodyText variant="large"><TextLink underline={false} variant="black" href="/terms-and-conditions">Terms & Conditions </TextLink></BodyText>
          <BodyText variant="large"><TextLink underline={false} variant="black" href="/privacy-policy">{config.brandConfig.privacyPolicySynonym}</TextLink></BodyText>
        </Group>

        <BodyText variant="large" align="center" desktopAlign="start">To see how we manage your personal information and other details, please see our <TextLink href="/privacy-policy">{config.brandConfig.privacyPolicySynonym}</TextLink>.</BodyText>

        <Group direction="horizontal" horizontalAlign="center" desktopHorizontalAlign="start" wrap="wrap" noDisplayWhenEmpty>
          {config.footer.partners.map(partner => <TextLink
            key={partner.name}
            target="_blank"
            rel="noopener noreferrer"
            href={partner.href}
            size="large"
          >
            Powered by {partner.name}
          </TextLink>)}
        </Group>
      </Group>
    </LayoutContainer>
  </Container>
}

function mapStateToProps(state: App.State) {
  return {
    regionCode: state.geo.currentRegionCode,
  }
}

export default connect(mapStateToProps)(CompactFooter)
